import { Container, Grid, Stack } from "@mui/material";
import { React, useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BannerSlider from "../../../components/BannerSlider";
import SubHeader from "../../../components/layout/SubHeader";
import OfferSlider from "../../../components/OfferSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import swal from "sweetalert";
import { Base_URL, Offers_URL } from "../../../serverUrl";
import DatePicker from "react-multi-date-picker";
import { Link, useNavigate, useParams } from "react-router-dom";
import { subMenuArrow } from "../../../utils/svg.file";
import {
  showExperienceList,
  TravelCategorySection,
  travelItemsid,
  TravelSection,
} from "../../../api/frontend";
import LoaderMain from "../../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
const TravelMotorbikeTours = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [date, setDate] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [enquiryType, setEnquiryType] = useState("travel");
  const [description, setDescription] = useState("");
  const [travelItemData, setTravelItemData] = useState([]);
  const [travelcategoryArray, setTravelCategory] = useState([]);
  const [travelPackage, setTravelPackage] = useState([]);
  const [termData, setTermData] = useState([]);
  const [highData, setHighData] = useState([]);
  const [ItneryData, setItneryData] = useState([]);
  const [tourData, settourData] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const submitEnquiry = () => {
    var data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("phone", Number(phoneNumber));
    data.append("date", date);
    data.append("type", enquiryType);
    data.append("description", description);

    var config = {
      method: "post",
      url: `${Base_URL}enquiry`,
      headers: {
        Accept: "application/json",
        token: "barrer ",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTimeout(() => {
          swal({
            title: "Mail Successfully Send!",
            text: "You clicked the button!",
            icon: "success",
            button: "Close",
          });
        }, 1500);
      })
      .catch(function (error) {
        setTimeout(() => {
          swal({
            title: "Failed!",
            text: "You clicked the button!",
            icon: "error",
            button: "Close",
          });
        }, 1500);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const handleTravelItem = useCallback(async () => {
    setIslodingData(true);

    const travelId = {
      id: id,
    };
    const response = await travelItemsid(travelId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelItemData(response.data.data[0]);
      let tempArr = JSON.parse(response.data.data[0].terms);
      setTermData(tempArr.sort((a, b) => a.termOrder - b.termOrder));
      let highArr = JSON.parse(response.data.data[0].highlights);
      setHighData(highArr.sort((a, b) => a.highlightOrder - b.highlightOrder));
      let itneryArr = JSON.parse(response.data.data[0].itnery);
      setItneryData(itneryArr.sort((a, b) => a.dayOrder - b.dayOrder));
      let tourArr = JSON.parse(response.data.data[0].tour);
      settourData(tourArr.sort((a, b) => a.guideOrder - b.guideOrder));
    } else {
    }
  }, [id]);

  useEffect(() => {
    handleTravelItem();
  }, [handleTravelItem]);
  const handlTravelCategory = async () => {
    setIslodingData(true);

    const response = await TravelCategorySection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelCategory(response.data.data);
    } else {
    }
  };
  const handlTravelSection = async () => {
    setIslodingData(true);

    const response = await TravelSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelPackage(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlTravelSection();
    handlTravelCategory();
  }, []);
  const categoryList = [];
  // eslint-disable-next-line array-callback-return
  travelcategoryArray?.map((item, index) => {
    categoryList.push({
      id: item.id,
      subLink: item.name,
      subUrl: "/travels-tours",
    });
  });
  categoryList.push({
    id: "2",
    subLink: "Cruises",
    subUrl: "/samadhi-cruises",
  });

  const MotorBikeArray = [];
  travelPackage?.map((item, index) => {
    Number(item.id) !== Number(id) &&
      MotorBikeArray.push({
        image: item.image,
        subcategory: item.category,
        title: item.title,
        shortDescription: item.discription,
        id: item.id,
        slug: item.slug,
        speed: "1000",
        dataspeed: "fade-up",
      });
  });

  const [experienceListArray, SetExperienceListArray] = useState([]);
  const handleExperienceList = async () => {
    setIslodingData(true);
    const response = await showExperienceList();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetExperienceListArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleExperienceList();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{travelItemData.meta_title}</title>
        <meta name="description" content={travelItemData.meta_description} />
        <link
          rel="canonical"
          href="https://www.samadhiretreats.com/travels/batu-caves"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="room-type-page motor-bike-one">
        <div className="main-bg">
          {/* <SubHeader pageTitle="Travels" subHeading={categoryList} /> */}
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={6}
                className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
              >
                <h3>
                  Travels
                  <span className="ms-2">{subMenuArrow}</span>
                </h3>
              </Col>
              <Col lg={6}>
                <ul className="ps-0 pt-20 ">
                  {travelcategoryArray.map((item, index) => (
                    <li key={index}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/travels-tours/${item.name
                              .toLowerCase()
                              .replace(/\s+/g, "-")}`
                          )
                        }
                      >
                        {item.name}
                      </Button>
                    </li>
                  ))}
                  <li>
                    <Button onClick={() => navigate("/samadhi-cruises")}>
                      Cruises
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className="travel-motorbike">
            <Container maxWidth="xl" className="border-dashed-ex">
              <h3>
                {travelItemData.title !== "null" ? travelItemData.title : ""}
              </h3>
            </Container>
            <Container maxWidth="xl" className="banner_container">
              <div
                className="banner-div"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                {travelItemData?.banner && (
                  <BannerSlider
                    BannerArray={
                      travelItemData?.banner &&
                      JSON.parse(travelItemData?.banner)
                    }
                  />
                )}
              </div>
            </Container>
          </div>
          <div className=" travel-motorbike-tours text-center">
            <Grid container justifyContent="center">
              <Grid item md={7} xs={12}>
                <p
                  className="package_duration_texteditor px-2"
                  data-aos="fade-up"
                  // data-aos-once="true"
                  data-aos-duration="3000"
                  dangerouslySetInnerHTML={{
                    __html: travelItemData.longdescription
                      ? travelItemData.longdescription
                      : "",
                  }}
                ></p>
              </Grid>
            </Grid>
          </div>
          <div className="vsklexprice-banner pb-152">
            <Container maxWidth="xl">
              <div className="center-slider exprcne border-dashed-ex">
                <h3 className="moterbike-title">Highlights </h3>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    // data-aos-once="true"
                  >
                    <div className="list-moterbike">
                      <ul>
                        {travelItemData?.highlights &&
                          highData
                            ?.slice(0, 3)
                            .map((item, index) => (
                              <li>{item.highlightText}</li>
                            ))}
                      </ul>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    data-aos="fade-down"
                    data-aos-duration="3000"
                    // data-aos-once="true"
                  >
                    <div className="list-moterbike">
                      <ul>
                        {travelItemData?.highlights &&
                          highData
                            ?.slice(3, 7)
                            .map((item, index) => (
                              <li>{item.highlightText}</li>
                            ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          {travelItemData?.mapImage ? (
            <div className="moterbike-map">
              <Container maxWidth="xl">
                <div className="border-dashed-ex map-size travel_map">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      data-aos="fade-up"
                      data-aos-duration="3000"
                      // data-aos-once="true"
                    >
                      <img
                        src={`${Offers_URL}${travelItemData?.mapImage}`}
                        alt=""
                        loading="lazy"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          ) : (
            ""
          )}

          <div className="moterbike-tour-section">
            <Container maxWidth="xl">
              <div className="border-btm content-moterbike border-dashed-ex">
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      {travelItemData.itnery &&
                        ItneryData?.slice(0, 3).map((item, index) => (
                          <div
                            className="moterbike-day"
                            // data-aos-once="true"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                          >
                            <h3>Duration</h3>
                            <p>{item.dayDescription}</p>
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      {travelItemData.itnery &&
                        ItneryData?.slice(3, 6).map((item, index) => (
                          <div
                            className="moterbike-day"
                            // data-aos-once="true"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                          >
                            <h3>Duration</h3>
                            <p>{item.dayDescription}</p>
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      {travelItemData?.itnery &&
                        ItneryData?.slice(6, 10).map((item, index) => (
                          <div
                            className="moterbike-day"
                            // data-aos-once="true"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                          >
                            <h3>Duration</h3>
                            <p>{item.dayDescription}</p>
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <div className="price-section">
            <Container maxWidth="xl">
              <div className="price-content-box">
                <Grid container spacing={3}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    // data-aos-once="true"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="moterbike-day">
                      <h3>Price (Excluded International Airfare)</h3>
                      <div className="mb-3">
                        <div
                          className="package_duration_texteditor"
                          dangerouslySetInnerHTML={{
                            __html: travelItemData.price,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    // data-aos-once="true"
                    data-aos="fade-down"
                    data-aos-duration="3000"
                  >
                    <div className="moterbike-day">
                      {tourData?.map((item) => {
                        return (
                          <>
                            <h3>{item.guideType}</h3>
                            <div className="mb-3">
                              <h6>{item.guideSubType}</h6>
                              <Stack direction="column" spacing={4}>
                                <div className="listprice">
                                  <p>{item.guideMain}</p>
                                </div>
                              </Stack>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    // data-aos-once="true"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="moterbike-day">
                      <div className="mb-3">
                        <h6 className="mb-3">Terms & Conditions</h6>
                        <Stack direction="column" spacing={4}>
                          <div className="listprice listnopric">
                            <p className="mb-3">Cancellation terms:</p>
                            <ul className="mb-3">
                              {travelItemData?.terms &&
                                termData?.map((item, index) => (
                                  <li>{item.termCondition}</li>
                                ))}
                            </ul>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>

          <div className="enquiry-form">
            <Container maxWidth="xl">
              <div
                className="enquirt-section border-btm"
                // data-aos-once="true"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <Grid container spacing={0} justifyContent="center">
                  <Grid item xl={8} lg={8} xs={12}>
                    <h3>Enquiry</h3>
                    <Stack direction="column" spacing={2}>
                      <input
                        placeholder="First Name"
                        className="enquiry-input"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        placeholder="Last Name"
                        className="enquiry-input"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <input
                        placeholder="Email"
                        className="enquiry-input"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        placeholder="Phone number"
                        className="enquiry-input"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />

                      <DatePicker
                        placeholder="Estimated tour date"
                        className="date-input enquiry-input"
                        style={{ height: "56px" }}
                        value={date}
                        onChange={(e) => setDate}
                      />
                      <textarea
                        placeholder="Message"
                        className="enquiry-textarea"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <button className="btn-send" onClick={submitEnquiry}>
                        Send
                      </button>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Tour</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={MotorBikeArray.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {MotorBikeArray?.map((item, index) => {
                    var gotIndex = index % 2 === 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div "
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          // data-aos-once="true"
                          onClick={() => navigate(`/travels/${item.slug}`)}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div "
                        data-aos="fade-down"
                        data-aos-duration="1000"
                        // data-aos-once="true"
                        onClick={() => navigate(`/travels/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      // data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img
                          src={`${Offers_URL}${item.banner}`}
                          alt=""
                          loading="lazy"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default TravelMotorbikeTours;
