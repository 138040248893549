import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/material";
import Slider from "react-slick";
import IMAGES from "../../../assets/images";
import SubHeader from "../../../components/layout/SubHeader";
import BannerSlider from "../../../components/BannerSlider";
import OfferSlider from "../../../components/OfferSlider";
import TestimonialSlider from "../../../components/TestimonalSlider";
import TabButton from "../TabButton";
import EventCard from "../EventCard";
import { Link, useNavigate } from "react-router-dom";
import { celebrationBlock, celebrationMalasiyaBanners, showfeaturedstory } from "../../../api/frontend";
import { useSearchParams } from "react-router-dom";
import { Featured_URL } from "../../../serverUrl";
import LoaderMain from "../../../admin/component/loader";
import { Helmet } from "react-helmet";
const {
  celebrateOne,
  stayBanner,
  vsklBanner,
  LineOne,
  LineTwo,
  LineThree,
  LineFour,
  experience1,
  experience6,
  EventOne,
  singOne,
  singTwo,
  experience8,
} = IMAGES;

export default function CelebrationEventMalasiya() {
  const navigate = useNavigate();

  const [place, setPlace] = useState("active");
  const [experienceArray, SetexperienceArray] = useState([]);
  const [BannerArray, setBannerArray] = useState([]);
  const [ContentArray, setContentArray] = useState([]);
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [searchParams] = useSearchParams();
  const [restaurantId, setRestaurantId] = useState("");
  const [isLoadingData, setIslodingData] = useState(true);


  const handleCelebrationBanner = async () => {
    setIslodingData(true)
    const response = await celebrationMalasiyaBanners();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setBannerArray(JSON.parse(response.data.data[0].image));
      setContentArray(response.data.data);
    } else {
    }
  };
  const handleCelebrationBlock = async () => {
    setIslodingData(true)
    const response = await celebrationBlock();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleCelebrationBlock();
    handleCelebrationBanner();
  }, []);

  let rows = [];

  BlockCelebration?.map((item, index) => {
    if (Number(item.location) === 1) {
      rows.push({
        image: item.cardimage,
        type: "CELEBRATIONS & EVENTS",
        title: item.title,
        shortDescription: item.description,
        id: item.id,
        other: item.other,
      });
    }
  });
  const handlefeaturedstory = async () => {
    setIslodingData(true)
    const response = await showfeaturedstory();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    handlefeaturedstory();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    setRestaurantId(catagoryId);
  }, [searchParams]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ContentArray[0]?.meta_title}</title>
        <meta name="description" content={ContentArray[0]?.meta_description} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className=" celebrate-singapore-page celebrate-malasiya-home">
        <div className=" main-bg">
          <Container>
            <div className=" ">
              <SubHeader
                pageTitle="Celebrations & Events"
                subHeading={[
                  {
                    id: "1",
                    subLink: "Malaysia",
                    subUrl: "/weddings-events-malaysia",
                  },
                  {
                    id: "2",
                    subLink: "Singapore",
                    subUrl: "/weddings-events-singapore",
                  },
                ]}
                categoryId={restaurantId}
              />
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <BannerSlider BannerArray={BannerArray} />
            </div>
          </Container>

          <div className="celebration_cards_main">
            <Container maxWidth="xl">
              <div
                className="mt-4"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
              >
                <TabButton />
              </div>
              <div
                className="text-description-div text-center pb-170"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
              >
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  className="mx-auto"
                >
                  <Grid md={7}>
                    <h2 className="big-font">{ContentArray[0]?.title}</h2>
                    <p className="">{ContentArray[0]?.description}</p>

                    <Button
                      className="dashed-btn mt-4 me-4"
                      onClick={() =>
                        navigate(
                          `/celebration-and-events-enquiry?catagoryId=${rows[0].title}&id=${rows[0].other}`
                        )
                      }
                    >
                      Enquiry
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="pt-200 pb-200">
                <Grid container spacing={4}>
                  {rows?.map((content, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          data-aos="fade-up"
                          data-aos-duration="1500"
                          data-aos-once="true"
                          onClick={() =>
                            navigate(`/celebration-event/${content.other}`)
                          }
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        data-aos-once="true"
                        onClick={() =>
                          navigate(`/celebration-event/${content.other}`)
                        }
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img
                          src={`${Featured_URL}${item.image}`}
                          alt=""
                          loading="lazy"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
