import { React, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  restaurantItemsid,
  restaurantItems,
  showExperienceList,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { subMenuArrow } from "../../utils/svg.file";
import { Button, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
export default function TamarindSpring() {
  let { slug, id } = useParams();
  const navigate = useNavigate();
  const [restaurantArray, setRestaurantArray] = useState([]);
  const [celebrtionArray, setCelebrationArray] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [tasteCardArray, setTasteCardArray] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);
  const [, setSlide] = useState(1);
  const handleRestaurantItem = async () => {
    setIslodingData(true);

    const restaurantData = {
      id: id,
    };
    const response = await restaurantItemsid(restaurantData);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationArray(response.data.celebration);
      setRestaurantArray(response.data.data[0]);
    } else {
    }
  };

  const celebrationArray = [];

  celebrtionArray?.map((item, index) => {
    celebrationArray.push({
      image: item.cardimage,
      title: item.title,
      description: item.description,
      id: item.id,
      other: item.other,
    });
  });
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const responsiveTaste = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  const handleRestaurantItems = async () => {
    setIslodingData(true);

    const response = await restaurantItems();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTasteCardArray(response.data.data);
    } else {
    }
  };

  let dayTrips = [];

  tasteCardArray?.map((item, index) => {
    Number(item.id) !== Number(id) &&
      dayTrips.push({
        id: item.id,
        image: item.cardImage,
        subcategory: item.category,
        title: item.title,
        descriptionTitle: item.descriptionTitle,
        shortDescription: item.short_description,
        slug: item.slug,
      });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    handleRestaurantItem();
    handleRestaurantItems();
    window.scrollTo(0, 0);
  }, [id]);
  useEffect(() => {
    let gallery = [];
    if (restaurantArray.galleryImage) {
      JSON.parse(restaurantArray.galleryImage)?.map((item, index) => {
        gallery.push({
          image: item,
        });
      });
      setGallery(gallery);
    }
  }, [restaurantArray.galleryImage]);

  const [experienceListArray, SetExperienceListArray] = useState([]);
  const handleExperienceList = async () => {
    const response = await showExperienceList();

    if (response.remote === "success") {
      SetExperienceListArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleExperienceList();
  }, []);
  const handleOpenLink = (link) => {
    let result = link.split("/")[0];
    if (result === "https:" || result === "http:") {
      window.open(link, "_blank");
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{restaurantArray.meta_title}</title>
        <meta name="description" content={restaurantArray.meta_description} />
        <link
          rel="canonical"
          href={`https://www.samadhiretreats.com/${slug}`}
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="taste-page tamarind-spring ">
        <div className=" main-bg">
          <div className="">
            <div className="sub-header">
              <Row className="g-0">
                <Col
                  lg={6}
                  className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                >
                  <h3>
                    {restaurantArray.title}
                    <span className="ms-2">{subMenuArrow}</span>
                  </h3>
                </Col>
                <Col lg={6}>
                  <ul className="ps-0 pt-20 ">
                    <li>
                      <Button
                        onClick={() => handleOpenLink(restaurantArray.booking)}
                      >
                        Book a table
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={() =>
                          navigate("/weddings-events-kuala-lumpur")
                        }
                      >
                        Celebrations & Events
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
              // // data-aos-once="true"
            >
              <img
                src={`${Offers_URL}${restaurantArray.banerImage}`}
                alt=""
                loading="lazy"
              />
            </div>
          </Container>
          <Container maxWidth="xl">
            <div className="tabs-section">
              <Grid container justifyContent="center">
                <Grid md={7} xs={12}>
                  <ul>
                    <li className="pe-0">
                      <Button
                        className="dashed-btn "
                        onClick={() => handleOpenLink(restaurantArray.booking)}
                      >
                        Reservation
                      </Button>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
            <div className="page-description-div">
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={8}
                  className="page-description-data tamarind-spring-text text-center"
                  data-aos="fade-up"
                  // data-aos-once="true"
                  // data-aos-duration="1500"
                >
                  <h3>{restaurantArray.title}</h3>
                  <span>{restaurantArray.descriptionTitle}</span>
                  <br></br>
                  <p
                    className="small-text mt-4 package_duration_texteditor"
                    dangerouslySetInnerHTML={{
                      __html: restaurantArray.middle_description,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="tabs-section  intro_text_tab">
              <Grid container spacing={3} justifyContent="center">
                <Grid md={7} xs={12}>
                  <ul className="d-flex justify-space-between">
                    <li>Tel: {restaurantArray.phone}</li>
                    <li
                      className="cursor-btn"
                      onClick={() => handleOpenLink(restaurantArray.url)}
                    >
                      Direction
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
          <div className="taste-center-card ">
            <Container maxWidth="xl">
              <div className="normal-card-section">
                <div className="scroll-slider-div content-none btn-slider-div">
                  <Carousel
                    speed={4000}
                    afterChange={function (previousSlide, _ref) {
                      var currentSlide = _ref.currentSlide;
                      // _ref.onMove;
                      setSlide(currentSlide + 1);
                    }}
                    arrows={true}
                    ssr={true}
                    partialVisbile={gallery.length < 3 ? false : true}
                    // sliderClass="centerslider"
                    responsive={responsiveTaste}
                    autoPlay={false}
                    infinite={false}
                    className="mt-100"
                  >
                    {gallery.map((content, index) => {
                      var gotIndex = index % 2 === 0;
                      return gotIndex ? (
                        <>
                          <div
                            className="normal-card-div "
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-duration="1000"
                            key={content.id}
                          >
                            <OfferSlider {...content} />
                          </div>
                        </>
                      ) : (
                        <div
                          className="normal-card-div "
                          data-aos="fade-down"
                          data-aos-once="true"
                          data-aos-duration="1500"
                          key={content.id}
                        >
                          <OfferSlider {...content} />
                        </div>
                      );
                    })}
                  </Carousel>

                  <div className="slider-text-data">
                    <Grid container spacing={3} justifyContent="center">
                      <Grid md={8} xs={12}>
                        <p>{restaurantArray.description}</p>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="celebration-div"
            data-aos="fade-up "
            data-aos-once="true"
            data-aos-duration="3000"
          >
            <Container maxWidth="xl">
              <div className="celebration-internal-div ">
                {" "}
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Celebrations & Events</h3>
                </Grid>
                <Grid
                  container
                  spacing={4}
                  justifyContent="center"
                  className="mt-60"
                >
                  {celebrationArray.map((content) => {
                    return (
                      <Grid
                        xs={12}
                        item
                        md={10}
                        className="normal-card-div cursor-btn"
                        key={content.id}
                        data-aos={content.dataspeed}
                        data-aos-duration={content.speed}
                        data-aos-once="true"
                        onClick={() =>
                          navigate(`/celebration-event/${content.other}`)
                        }
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Restaurant</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={dayTrips.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {dayTrips.slice(0, 5).map((item, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div "
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-once="true"
                          onClick={() => {
                            navigate(`/${item.slug}/${item.id}`);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        data-aos-once="true"
                        onClick={() => {
                          navigate(`/${item.slug}/${item.id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 style={{ fontWeight: "500" }}>Story</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.slug}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt=""
                        loading="lazy"/>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
