import {React,useEffect, useState} from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import Slider from "react-slick";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Carousel from "react-multi-carousel";
import { exclusiveoffers, exclusiveofferswithId, restaurantDetails, restaurantItems, restaurantNameList, showExperienceIdsList, showExperienceList } from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";

const {
  travelBanner,
  taste1,
  taste2,
  taste3,
  taste4,
  taste5,
  experience1,
  experience2,
  tasteBanner,
  offerOne,
  offerTwo,
  offerThree,
  taste8,
  taste9,
} = IMAGES;
const dayTrips = [
  {
    image: offerOne,
    type: "OFFERS",
    title: "Weekend Brunch",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: offerTwo,
    type: "OFFERS",
    title: "Tgif Set Lunch",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: offerThree,
    type: "OFFERS",
    title: "Work from Tamarind Hill",
    description:
      "SOMA is your source of wellness. A health system that embraces a holistic approach to total healing of mind, body and soul through a combination of ancient yogic practices… ",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
  {
    image: offerOne,
    type: "OFFERS",
    title: "Weekend Brunch",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "4",
    speed: "3000",
    dataspeed: "fade-down",
  },
];
export default function TasteHomePage() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [restaurantName, setRestaurantName] = useState([]);
  const [restaurantDetail, setRestaurantDetails] = useState([]);
  const [tasteCardArray, setTasteCardArray] = useState([]);
  const [travelArray, setTravelArray] = useState([]);
  const [rows, setRows] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [searchParams] = useSearchParams();
  const [isLoadingData, setIslodingData] = useState(true);
  const handleRestaurantName = async () => {
    setIslodingData(true);
    const response = await restaurantNameList();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const handleRestaurantDetails = async () => {
    setIslodingData(true);

    const response = await restaurantDetails();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRestaurantDetails(response.data.data[0]);
    } else {
    }
  };
  const handleRestaurantItems = async () => {
    setIslodingData(true);

    const response = await restaurantItems();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTasteCardArray(response.data.data);
    } else {
    }
  };
  const handleexclusive = async () => {
    setIslodingData(true);
    const id = "2";
    const response = await exclusiveofferswithId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setTravelArray(response.data.data);
    } else {
    }
  };
  const categoryList = [];
  restaurantName?.map((item, index) => {
    categoryList.push({
      // id: item.id,
      subLink: item.title,
      subUrl: `/${item.slug}/${item.id}`,
    });
  });
  useEffect(() => {
    handleRestaurantName();
    handleRestaurantDetails();
    handleRestaurantItems();
    handleexclusive();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    setRestaurantId(catagoryId);
    const rows = [];
    const idMatchData = [];
    tasteCardArray?.forEach((item) => {
      if (Number(item.restaurant) === Number(restaurantId)) {
        idMatchData.push({
          id: item.id,
          image: item.cardImage,
          subcategory: item.category,
          title: `${item.title}, ${item.restaurant}`,
          descriptionTitle: item.descriptionTitle,
          shortDescription: item.short_description,
        });
      } else {
        rows.push({
          id: item.id,
          image: item.cardImage,
          subcategory: item.category,
          title: `${item.title}, ${item.restaurant}`,
          descriptionTitle: item.descriptionTitle,
          shortDescription: item.short_description,
          slug: item.slug,
        });
      }
      if (restaurantId) {
        setRows([...idMatchData]);
      } else {
        setRows([...rows]);
      }
    });
  }, [searchParams, tasteCardArray]);

  var experienceSlide = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  const [experienceListArray, SetExperienceListArray] = useState([]);
  const handleExperienceList = async () => {
    const id = "2";
    const response = await showExperienceIdsList(id);

    if (response.remote === "success") {
      SetExperienceListArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleExperienceList();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{restaurantDetail.meta_title}</title>
        <meta name="description" content={restaurantDetail.meta_description} />
        <link rel="canonical" href="https://www.samadhiretreats.com/fine-dining-restaurants-kuala-lumpur-singapore"/>
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="taste-page taste-home">
        <div className=" main-bg">
          
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3>Restaurants</h3>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div "
              data-aos="fade-up"
             
              data-aos-duration="3000"
              
            >
             
              <img src={`${Offers_URL}${restaurantDetail.image}`} alt=""
              loading="lazy"/>
            </div>
          </Container>
          <div className="page-description-div">
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={7}
                  className="page-description-data"
                  data-aos="fade-up"
                
                >
                 <h3 className="text-center mb-4 stay-title ">
                    {restaurantDetail.other}
                  </h3>
                  <p>{restaurantDetail.description}</p>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="taste-center-card">
            <Container maxWidth="xl">
              <div className="normal-card-section cursor-btn">
                <Grid container spacing={4}>
                  {rows?.map((content, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          // // data-aos-once="true"
                          data-aos="fade-up"
                          data-aos-duration="2000"
                          onClick={() =>
                            navigate(`/${content.slug}/${content.id}`)
                          }
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        // // data-aos-once="true"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        onClick={() =>
                          navigate(`/${content.slug}/${content.id}`)
                        }
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Offers</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={false}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {travelArray.map((item, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div "
                          // data-aos-once="true"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                          onClick={() => navigate(`/offers/${item.slug}`)}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div "
                        // // data-aos-once="true"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        onClick={() => navigate(`/offers/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      // // data-aos-once="true"
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt=""
                        loading="lazy"/>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
