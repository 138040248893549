import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";

const { other1, other2, vsklTwo, mapImg, vsklonenewgallery, ex1, ex2, ex3 } =
  IMAGES;
const exclusiveOffer = [
  {
    image: other1,
    type: "STAYS",
    title: "Japamala, Tioman Island",
    description:
      "Planning the perfect celebration begins with selecting your dream venue. Encircled by the raw beauty of nature, this jungle-luxe restaurant’s long meditative walkway, treetop vistas, water features and stone-carved statues exude Asian elegance and a warm and welcoming ambience. ",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: other2,
    type: "STAYS",
    title: "The Townhouse, Kuala Lumpur",
    description:
      "At Samadhi we pride ourselves on being an eco-conscious organisation and we’ve been that way since our inception decades ago. Committed to preserving nature and protecting the health of our planet, we ensure that our properties are created from sustainable… ",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: vsklTwo,
    type: "STAYS",
    title: "Villa Samadhi, Singapore",
    description:
      "Planning the perfect celebration begins with selecting your dream venue. Encircled by the raw beauty of nature, this jungle-luxe restaurant’s long meditative walkway, treetop vistas, water features and stone-carved statues exude Asian elegance and a warm and welcoming ambience.",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
];
export default function VSKLGalleryOne() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const responsiveProperty = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  var propertiesSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const MapArray = [
    {
      image: mapImg,
      type: "",
      title: "Getting Here",
      description:
        "Discover new cultures and destinations. Experience a true sense of place by making a real connection with its people. Curated excursions designed for the Traveller, not the Tourist. Adventure seekers only..",
      id: "1",
    },
  ];
  const galleryArray = [
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
  ];
  return (
    <>
      <div className="travel-category vskl-gallery gallery-one">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Villa Samadhi Kuala Lumpur"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Gallery ",
                    subUrl: "/villa-samadhi-kuala-lumpur/gallery",
                  },
                  { id: "1", subLink: "Stays" },
                  { id: "2", subLink: "Tastes" },
                  { id: "3", subLink: "Story" },
                  { id: "4", subLink: "Wellness" },
                  { id: "5", subLink: "Celebrations" },
                  { id: "6", subLink: "Gift Card" },
                  { id: "7", subLink: "Offers" },
                ]}
              />
            </div>
          </Container>
          <div className="gallery-div-massonary-min">
            <div className="gallery-div-masonary center-slider">
              <Carousel
                slidesToShow={1}
                slidesToScroll={1}
                centerMode={true}
                arrows={true}
                ssr={true}
                autoPlaySpeed={1500}
                responsive={responsive}
                autoPlay={true}
                infinite={true}
              >
                {galleryArray.map((item, index) => (
                  <Box className="" key={index}>
                    <img src={item.img} loading="lazy" />
                  </Box>
                ))}
              </Carousel>
              <div className="slider-count-text text-center">
                <span>1 </span> / <span> 12</span>
              </div>
            </div>
          </div>
          <section>
            <Container maxWidth="xl">
              <div className="day-trips-section">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Other Properties</h3>
                </Grid>
                <div>
                  <Carousel
                    autoPlaySpeed={1200}
                    arrows={false}
                    ssr={true}
                    partialVisbile={true}
                    sliderClass="centerslider"
                    responsive={responsiveProperty}
                    autoPlay={false}
                    infinite={true}
                    className="mt-100"
                  >
                    {exclusiveOffer.slice(0, 5).map((item, index) => (
                      <div key={index} className="bottom-cards-div ">
                        <OfferSlider {...item} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Container>
          </section>
          <Container maxWidth="xl">
            <div className="book-offer-div">
              <Grid
                container
                spacing={3}
                className="text-center section-head align-items-center"
              >
                <Grid md={3} item></Grid>
                <Grid md={3} item>
                  <h3>Offers</h3>
                </Grid>
                <Grid md={3} item>
                  <Button className="white-btn">Book Now</Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
