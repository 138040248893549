import { Box, Container, Divider, Grid } from "@mui/material";
import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import IMAGES from "../../assets/images";
import BannerSlider from "../../components/BannerSlider";
import SubHeader from "../../components/layout/SubHeader";
import TestimonialSlider from "../../components/TestimonalSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OfferSlider from "../../components/OfferSlider";
import ExclusiveBook from "../../components/exclusive-book";
import {
  findExperienceList,
  showExperienceList,
  showfeaturedstory,
} from "../../api/frontend";
import { Featured_URL } from "../../serverUrl";
import { Helmet } from "react-helmet";
const {
  VSKLThaipusamExperiencenew,
  RoomTypeBanner,
  celebrationBanner,
  expricenceNew1,
  ex1,
  ex2,
  ex3,
  experience1,
  experience2,
  vsklBanner,
} = IMAGES;
const VSKLThaipusamExperience = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [experienceGallery, setExperienceGallery] = useState([]);
  const [experienceDescription, setExperienceDescription] = useState("");
  const [experienceId, setExperienceId] = useState("");
  const [experienceTitle, setExperienceTitle] = useState("");
  const [experienceMetaTitle, setExperienceMetaTitle] = useState("");
  const [experienceMetaDescription, setExperienceMetaDescription] = useState("");
  const [experienceListArray, SetExperienceListArray] = useState([]);
  const [experienceArray, SetexperienceArray] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const experienceArray = [
  //   {
  //     image: vsklBanner,
  //     title: "KL’s Food Tour By Motorbike",
  //     description:
  //       "A inconspicuous wooden gate, just off KL’s embassy row marks the entrance to one of KL’s best hidden secrets - Villa Samadhi. As guests pass through the modest entrance, the grounds open up revealing an urban oasis.",
  //     id: "1",
  //   },
  //   {
  //     image: vsklBanner,
  //     title: "KL’s Food Tour By Motorbike",
  //     description:
  //       "A inconspicuous wooden gate, just off KL’s embassy row marks the entrance to one of KL’s best hidden secrets - Villa Samadhi. As guests pass through the modest entrance, the grounds open up revealing an urban oasis.",
  //     id: "2",
  //   },
  // ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const handleExperienceDetails = async (id) => {
    const response = await findExperienceList(id);

    if (response.remote === "success") {
      setExperienceGallery(JSON.parse(response.data.data[0].slider));
      setExperienceDescription(response.data.data[0].description);
      setExperienceTitle(response.data.data[0].title);
      setExperienceMetaTitle(response.data.data[0].meta_title);
      setExperienceMetaDescription(response.data.data[0].meta_description);
      //  setRetreatsDetails(JSON.parse(response.data.data[0].gallery))
      //  setRetreatsid(JSON.parse(response.data.data[0].id))
    } else {
    }
  };
  const handleExperienceList = async () => {
    const response = await showExperienceList();

    if (response.remote === "success") {
      SetExperienceListArray(response.data.data);
    } else {
    }
  };
  const handlefeaturedstory = async () => {
    const response = await showfeaturedstory();

    if (response.remote === "success") {
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  // useEffect(() => {
  //   const catagoryId = searchParams.get("experience");
  //   setExperienceId(catagoryId);
  //   handleExperienceDetails(catagoryId);
  //   handleExperienceList();
  //   handlefeaturedstory();
  // }, [searchParams]);
  useEffect(() => {


    handleExperienceDetails(id);
    setExperienceId(id);
    handleExperienceList();
    handlefeaturedstory();
  }, [id]);

  let rows = [];

  experienceListArray?.forEach((item) => {
    rows.push({
      id: item.id,
      image: item.cardImage,
      subcategory: "STORY",
      title: item.title,
      shortDescription: item.shortdescription,
      slug: item.other,
      linktext: "Discover",
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{experienceMetaTitle}</title>
        <meta name="description" content={experienceMetaDescription} />
      </Helmet>
      <div className="room-type-page experience-thaipusam-page experience-section-page">
        <div className="experience-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Stories"
                subHeading={[
                  {
                    // id: "0",
                    subLink: "Samadhian",
                    subUrl: "/samadhi-experience",
                  },
                  {
                    // id: "1",
                    subLink: "Culture",
                    subUrl: "/samadhi-experience",
                  },
                  // { id: "2", subLink: "Discover", subUrl: "/samadhi-experience" },
                  {
                    // id: "3",
                    subLink: "Celebrations",
                    subUrl: "/samadhi-experience",
                  },
                  {
                    // id: "4",
                    subLink: "Wellness",
                    subUrl: "/samadhi-experience",
                  },
                ]}
              />
            </div>
          </Container>

          <div className="vskl-experience">
            <Container maxWidth="xl">
              <h3>{experienceTitle}</h3>
            </Container>
            <Container maxWidth="xl" className="banner_container">
              <div
                className="banner-div"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <BannerSlider BannerArray={experienceGallery} />
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div
              className=" experience-content-text text-center "
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            >
              <Grid container justifyContent="center">
                <Grid items xl={7} lg={9}>
                  <p>{experienceDescription}</p>
                </Grid>
              </Grid>
            </div>
          </Container>
          <div className="vsklexprice-banner">
            <Container maxWidth="xl">
              <div className="center-slider exprcne py-100  btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  // ssr={true}
                  partialVisbile={rows.length < 2 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={rows.length < 2 ? false : true}
                >
                  {rows?.map((item, index) => {
                    return (
                      item.slug !== experienceId && (
                        <div
                          className=" cursor-btn"
                          key={index}
                          data-aos={item.dataspeed}
                          data-aos-duration={item.speed}
                          data-aos-once="true"
                          onClick={() => {
                            navigate(
                              `/experiences-details/${item.slug}`
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          <OfferSlider {...item} />
                        </div>
                      )
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img
                          src={`${Featured_URL}${item.image}`}
                          alt=""
                          loading="lazy"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default VSKLThaipusamExperience;
