import { Grid } from "@mui/material";
import React from "react";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "../../customComponents/row";
import OfferSlider from "../../components/OfferSlider";
import { Helmet } from "react-helmet";

import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { exclusiveofferswithId, homecontent } from "../../api/frontend";
import LoaderMain from "../../admin/component/loader";
import { Offers_URL } from "../../serverUrl";
import CookieConsent from "../../components/Cookies/CookieConsent";

const {
  newShow,
  banner,
  BannerNew,
  bottomCardPicTwo,
  bottomCardPicThree,
  bottomCardPicOne,
  picleftOne,
  picleftTwo,
  picleftThree,
} = IMAGES;

export default function Homepage() {

  

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  
  useEffect(() => {
    const debouncedZoomBannerOnScroll = debounce(zoomBannerOnScroll, 100);
    document.addEventListener("scroll", debouncedZoomBannerOnScroll);
    return () => {
      document.removeEventListener("scroll", debouncedZoomBannerOnScroll);
    };
  }, []);

  const navigate = useNavigate();
  const [zoomPicBanner, setzoomPicBanner] = useState("");
  const [homeData, setHomeData] = useState("");
  const [travelArray, setTravelArray] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const zoomBannerOnScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    if (scrollTop > 50) {
      setzoomPicBanner("zoom-banner");
    } else {
      setzoomPicBanner("");
    }
  };
  const handleexclusive = async () => {
    setIslodingData(true);
    const id = '5';
    const response = await exclusiveofferswithId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelArray(response.data.data);
    } else {
    }
  };
  const handleHomeContent = async () => {
    setIslodingData(true);
    const response = await homecontent();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setHomeData(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    handleexclusive();
    handleHomeContent();
  }, []);
  React.useEffect(() => {
    document.addEventListener("scroll", zoomBannerOnScroll);
    return () => {
      document.removeEventListener("scroll", zoomBannerOnScroll);
    };
  }, []);

  window.addEventListener("scroll", () => {
    let elem = document.getElementById("keys");
    let scrollY = window.scrollY / 10;
    elem.style.backgroundSize = 100 + scrollY + "%";
  });
  var exclusiveSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{homeData.meta_title}</title>
        <meta name="description" content={homeData.meta_description} />
        <link rel="canonical" href="https://www.samadhiretreats.com" />
        <meta
          name="keywords"
          content="luxury boutique resorts, fine dining restaurant in kuala lumpur, 5 star hotel in kuala lumpur, best boutique hotel kuala lumpur, best hotel for honeymoon kuala lumpur, best resort kuala lumpur, boutique hotel kuala lumpur, boutique hotel malaysia, boutique resorts malaysia, retreat kuala lumpur, retreat malaysia, retreat resort malaysia, retreat near kuala lumpur, nature getaway malaysia"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      {isLoadingData ? <LoaderMain /> : ""}

      <Grid>
        <Grid className="home-page justify-content-center topspace">
          <CookieConsent />
          <div className="top-gradient-div">
            <section id="keys"></section>

           
            <div className="page-description-div">
              <Container maxWidth="xl">
                <Grid>
                  <Grid item md={12}>
                    <Grid
                      className="heading-div"
                      data-aos="fade-left
                      "
                      // data-aos-once="true"
                      data-aos-duration="1000"
                    >
                     
                      <h3>{homeData.home_title}</h3>
                     
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} className="w-100">
                    <div className="home-tab-section text-center">
                      <Grid container justifyContent="center">
                        <Grid
                          sm={12}
                          md={5}
                          item
                          className="d-flex align-items-center justify-content-between"
                        >
                          <Button
                            variant="text"
                            onClick={() =>
                              navigate("/boutique-hotels-villas-resorts")
                            }
                          >
                            Holidays
                          </Button>
                          <Button
                            variant="text"
                            onClick={() =>
                              navigate(
                                "/fine-dining-restaurants-kuala-lumpur-singapore"
                              )
                            }
                          >
                            {" "}
                            Dining
                          </Button>

                          <Button
                            variant="text"
                            onClick={() =>
                              navigate("/weddings-events-kuala-lumpur")
                            }
                          >
                            Celebrations
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    md={8}
                    xl={8}
                    className="page-description-data"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <p>{homeData.discription}</p>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
          <div className="center-big-card-list">
            <Container maxWidth="xl">
              <div className="">
                <Row className="">
                  <Col
                    xs={12}
                    md={7}
                    data-aos="fade-right"
                    data-aos-duration="3000"
                  >
                    <div
                      className="big-cards-div retreats_div cursor-btn"
                      onClick={() =>
                        navigate("/boutique-hotels-villas-resorts")
                      }
                    >
                      <Grid className="card-design-big-one">
                        <img
                          src={`${Offers_URL}${homeData.stayimage}`}
                          alt=""
                          loading="lazy"
                          width={687}
                          height={642}
                          priority={false}
                        />

                        <Grid className="cards heading">
                          <h6>RETREATS</h6>
                          <h4>{homeData.staytitle}</h4>
                          <p>{homeData.staydiscription}</p>
                          <Link to="#">Discover</Link>
                        </Grid>
                      </Grid>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={5}
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div
                      className="big-cards-div cursor-btn"
                      onClick={() =>
                        navigate(
                          "/fine-dining-restaurants-kuala-lumpur-singapore"
                        )
                      }
                    >
                      <Grid className="card-design-big-two">
                        <img
                          src={`${Offers_URL}${homeData.diningimage}`}
                          alt=""
                          loading="lazy"
                          priority={false}
                          width={541}
                          height={721}
                        />

                        <Grid className="cards heading">
                          <h6>RESTAURANTS</h6>
                          <h4>{homeData.diningtitle}</h4>
                          <p>{homeData.diningdiscription}</p>
                          <Link to="#">Discover</Link>
                        </Grid>
                      </Grid>
                    </div>
                  </Col>
                  <Col
                    md={{ span: 8, offset: 4 }}
                    data-aos="fade-left"
                    data-aos-duration="3000"
                  >
                    <div
                      className="big-cards-div cursor-btn"
                      onClick={() => navigate("/travels-tours")}
                    >
                      <Grid className="card-design-big-three">
                        <img
                          src={`${Offers_URL}${homeData.travelimage}`}
                          alt=""
                          loading="lazy"
                          width={880}
                          priority={false}
                          height={530}
                        />
                        <Grid className="cards heading">
                          <h6>TRAVELS</h6>
                          <h4>{homeData.traveltitle}</h4>
                          <p>{homeData.traveldiscription}</p>
                          <Link to="#">Discover</Link>
                        </Grid>
                      </Grid>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div className="border-btm"></div>
          </Container>
          <div className="bottom-card-list pt-200">
            <Container maxWidth="xl">
              <Grid className="text-center pb-120 ex-title">
                <h3 className="ex-title">Offers</h3>
              </Grid>
           
              <div className="btn-slider-div ">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  partialVisbile={travelArray.length < 4 ? false : true}
                  // partial
                >
                  {travelArray.map((item, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                          onClick={() => navigate(`/offers/${item.slug}`)}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div "
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        onClick={() => navigate(`/offers/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
