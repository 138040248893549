import { React, useEffect } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Slider from "react-slick";
import IMAGES from "../../assets/images";
import SubHeader from "../../components/layout/SubHeader";
import BannerSlider from "../../components/BannerSlider";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Cruisepackage,
  CruisepackageEnquiry,
  exclusiveoffers,
  FindCruisepackage,
  showfeaturedstory,
} from "../../api/frontend";
import { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import { Featured_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
const {
  cruiseBanner,
  celebrateOne,
  stayBanner,
  vsklBanner,
  yatchBanner,
  yatchNew,
  experience9,
  experience6,
  Offer1,
  Offer2,
  Offer3,
  dakota,
  cruiseOne,
  cruiseTwo,
} = IMAGES;

export default function CruiseYatch() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [experienceArray, SetexperienceArray] = useState([]);
  const [cruiseDetails, setCruiseDetails] = useState([]);
  const [cruiseCards, setCruiseCards] = useState([]);
  const [cruiseGallery, setCruiseGallery] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const BannerArray = [yatchBanner, yatchBanner, yatchBanner];

 
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };

  const packageSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };
  const handlefeaturedstory = async () => {
    setIslodingData(true);

    const response = await showfeaturedstory();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  const handleFindCruisepackage = async (catagoryId) => {
    setIslodingData(true);

    const response = await FindCruisepackage(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCruiseDetails(response.data.data[0]);
      setCruiseGallery(JSON.parse(response.data.data[0].gallery));
    } else {
    }
  };
  const handleCruisePackage = async (catagoryId) => {
    setIslodingData(true);

    const response = await CruisepackageEnquiry(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCruiseCards(response.data.data);
      // window.scrollTo(0, 0);
    } else {
    }
  };
  useEffect(() => {
    handlefeaturedstory();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const catagoryId = searchParams.get("package");
    handleFindCruisepackage(catagoryId);
    handleCruisePackage(catagoryId);
    window.scrollTo(0, 0);
  }, [searchParams]);
  let rows = [];

  cruiseCards?.map((item, index) => {
    rows.push({
      image: item.cardImage,
      subcategory: "CRUISES",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      speed: "2000",
      dataspeed: "fade-up",
    });
  });

  const [travelArray, setTravelArray] = useState([]);
  const handleexclusive = async () => {
    setIslodingData(true);

    const response = await exclusiveoffers();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleexclusive();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Boutique Hotels, Resorts, Restaurants & Private Tours | Samadhi
          Retreats
        </title>
        <meta
          name="description"
          content="Discover your next travel destination with  Samadhi Retreats, where curated stays, and unforgettable travel experiences await. Book Now"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className=" travel cruise-yatch">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div  mb-4">
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={6}
                    className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                  >
                    <h3 onClick={() => navigate(`/samadhi-cruises`)}>
                      Cruise
                      <span className="ms-2">{subMenuArrow}</span>
                    </h3>
                  </Col>
                  <Col lg={6}>
                    <ul className="ps-0 pt-20 pb-15">
                      <li>
                       
                        <Button
                          onClick={() =>
                            navigate("/samadhi-cruises?category=package")
                          }
                        >
                          Packages
                        </Button>
                      </li>
                      <li>
                       
                        <Button
                          onClick={() =>
                            navigate("/samadhi-cruises?category=activity")
                          }
                          className={
                            location.pathname === "/cruises-activities"
                              ? "active"
                              : ""
                          }
                        >
                          Activities
                        </Button>
                      </li>
                     
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div className="banner-div">
              <h2
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
              >
                {cruiseDetails?.title}
              </h2>
              <div data-aos="fade-up" data-aos-duration="3000">
                <BannerSlider BannerArray={cruiseGallery} />
              </div>
            </div>
          </Container>

          <div
            className="celebration_cards_main"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1000"
          >
            <Container maxWidth="xl">
              <div className="text-description-div text-center">
                <Grid container spacing={3} justifyContent="center">
                  <Grid md={7}>
                    <div className="">
                      <p className="large-text mb-100">
                        “{cruiseDetails?.detailDescription}!”
                      </p>
                      <Button
                        className="dashed-btn"
                        onClick={() => navigate("/cruise/cruise-charter")}
                      >
                        Enquiry
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div className="on-board-life">
              <Carousel
                autoPlaySpeed={1200}
                arrows={true}
                responsive={packageSlider}
                autoPlay={false}
                infinite={true}
                className="mt-100"
              >
             

                {rows?.map((content) => {
                  return (
                    <div
                      // xs={12}
                      // item
                      // md={10}
                      className="normal-card-div mx-auto"
                      key={content.id}
                      data-aos={content.dataspeed}
                      data-aos-once="true"
                      data-aos-duration={content.speed}
                      onClick={() =>
                        navigate(`/cruise/cruise-charter?package=${content.id}`)
                      }
                    >
                      <OfferSlider {...content} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="border-btm"></div>
          </Container>
          <div className=" exclusive-card">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Offers</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={true}
                  className="mt-100"
                >
                  {travelArray?.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div "
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      data-aos-once="true"
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Featured_URL}${item.image}`} alt=""
                        
                        loading="lazy"/>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
