import { React, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Container } from "@mui/material";
import IMAGES from "../../assets/images";
import { Col, Row, Spinner } from "react-bootstrap";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { checkPayment, manageticketDataId, updatecheckPayment } from "../../api/frontend";
import { Ticket_URL } from "../../serverUrl";
import axios from "axios";
import swal from "sweetalert";

export default function CarnivalTicket() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [titleData, setTitleData] = useState("");
  const [imageData, setImageData] = useState("");
  const [checkedData, setCheckedData] = useState({});
  const [quantityData, setQuantityData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);
  const [isStatus, setIsStatus] = useState(false);
  const [paymentId, setPaymentId] = useState();
  let { id } = useParams();
  const handleTickeData = async () => {
    setIslodingData(true);

    const response = await manageticketDataId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTitleData(response.data.data[0].title);
      setImageData(response.data.data[0].image);
      setTicketData(JSON.parse(response.data.data[0].ticket));
    } else {
    }
  };
  useEffect(() => {
    handleTickeData();
  }, [id]);

  const datas = {
    checkedData,
    quantityData,
  };

  const createBill = async () => {
    setIsStatus(true);
    let totalAmounts = 0;
    const checkedData = [];
    const quantityData = [];
    for (let i = 0; i < Object.keys(datas.checkedData).length; i++) {
      const checked = datas.checkedData[Object.keys(datas.checkedData)[i]];
      const quanitty = datas.quantityData[Object.keys(datas.checkedData)[i]];
      if (checked && quanitty) {
        checkedData.push({ checked, quanitty });
      }
    }

    for (let i = 0; i < checkedData.length; i++) {
      const amount = checkedData[i].checked.amount;
      const quantity = parseInt(checkedData[i].quanitty.quantity);

    
      totalAmounts += amount * quantity;
    }


    let newAmount = totalAmounts*100
    let changeamount = newAmount.toFixed(0)
    const addgiftbookinPayload = {
      name: `${firstName} ${lastName}`,
      email: email,
      mobile: phoneNumber,
      amount: parseInt(changeamount),
      checkedData: checkedData,
    };
    const response = await checkPayment(addgiftbookinPayload);

    if (response.remote === "success") {
      setIsStatus(false);
      const resposeData = response.data.data;
      if (resposeData.error) {
        swal(resposeData.error.message[0]);
      }
      const parsedData = response.data.data;
      if (parsedData.url) {
        setPaymentId(parsedData.id)

      
          window.location.href = parsedData.url;
        
        // const elem = document.createElement("a");
        // elem.target = "_blank";
        // elem.href = parsedData.url;
        // elem.click();
      }
      // window.location.reload();
    } else {
    }
  };
  
  
 
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weddings & Events | Venues Kuala Lumpur | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="From private executive meetings to breathtaking jungle-enclave weddings, discover versatile event spaces  that's perfect for you."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <Box className="carnival_ticket">
        <Box className="celebration-enquiry">
          <Box className=" main-bg">
            <Container maxWidth="xl">
              <Box
                className="sub-header-Box "
                sx={{ marginBottom: { xs: "10px", lg: "30px", xl: "30px" } }}
              >
                <Box className="sub-header">
                  <Row className="g-0">
                    <Col
                      lg={12}
                      className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
                    >
                      <h3>{titleData}</h3>
                    </Col>
                  </Row>
                </Box>
              </Box>
              <Box className="border-dashed-ex"></Box>
            </Container>
            <Container maxWidth="xl">
              <Grid
                container
                justifyContent="center"
                spacing={{ xs: 0, md: 4 }}
              >
                <Grid md={10}>
                  {" "}
                  <Grid container justifyContent="center" spacing={4}>
                    <Grid md={7} xs={12} item>
                      <Box className="carnival_banner_left ">
                        <img src={`${Ticket_URL}${imageData}`} alt=""
                        loading="lazy"/>
                      </Box>
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Box className="enquiry-form ">
                        <Box className="enquirt-section  pt-270">
                          <Grid container spacing={0} justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              xl={12}
                              lg={12}
                              data-aos="fade-up"
                              data-aos-duration="2000"
                              data-aos-once="true"
                            >
                              <h3 className="text-center">Enquiry</h3>
                              <Typography
                                sx={{ color: "#d9ccc5", marginBottom: "10px" }}
                              >
                                Fill in the Details Below
                              </Typography>
                              <Stack direction="column" spacing={2}>
                                <input
                                  placeholder="First Name"
                                  className="enquiry-input"
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                <input
                                  placeholder="Last Name"
                                  className="enquiry-input"
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                                <input
                                  placeholder="Email"
                                  className="enquiry-input"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                  placeholder="Phone number"
                                  className="enquiry-input"
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                                <label
                                  style={{
                                    color: "#d9ccc5",
                                    marginTop: "50px",
                                  }}
                                >
                                  Choose Ticket
                                </label>

                                {ticketData?.map((item, index) => {
                                  const options = [];

                                  for (
                                    let i = 1;
                                    i <= Number(item.ticketQuantity);
                                    i++
                                  ) {
                                    options.push(i);
                                  }
                                  return (
                                    <>
                                      <Box
                                        className="ticket_list_card"
                                        sx={{
                                          display: "flex",
                                          paddingRight: { xs: "0", lg: "20px" },
                                          marginBottom: "20px!important",
                                        }}
                                      >
                                       
                                        <Box
                                          className="ticket_right_content"
                                          sx={{ marginLeft: "10px" }}
                                        >
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <FormControlLabel
                                              sx={{
                                                color: "#d9ccc5",

                                                "& .MuiFormControlLabel-label":
                                                  {
                                                    fontWeight: "400!important",
                                                  },
                                              }}
                                              control={
                                                <Checkbox
                                                  // defaultChecked
                                                  sx={{
                                                    padding: {
                                                      xs: "0px 2px 5px 10px",
                                                      lg: "9px",
                                                    },
                                                    color: "#d9ccc5",
                                                    fontSize: {
                                                      xs: "1.1rem",
                                                      lg: "1.2rem",
                                                    },
                                                    "&.Mui-checked": {
                                                      color: "#d9ccc5",
                                                    },
                                                  }}
                                                  onChange={(e) =>
                                                    setCheckedData({
                                                      ...checkedData,
                                                      [index]: {
                                                        checked:
                                                          e.target.checked,
                                                        name: item.ticketTitle,
                                                        amount: Number(
                                                          item.ticketAmount
                                                        ),
                                                      },
                                                    })
                                                  }
                                                />
                                              }
                                              label={item.ticketTitle}
                                            />
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                fontSize: {
                                                  xs: "1.1rem",
                                                  lg: "1.2rem",
                                                },
                                                fontWeight: "bold",
                                                color: "#d9ccc5",
                                                letterSpacing: "0.9px",
                                              }}
                                            >
                                              {item.ticketAmount}MYR
                                            </Typography>
                                          </Stack>
                                          <Typography
                                            sx={{
                                              color: "#fff",
                                              lineHeight: { xs: "18px" },
                                            }}
                                          >
                                            {item.ticketDescription}
                                          </Typography>
                                          <Stack
                                            direction="row"
                                            marginTop="8px"
                                          >
                                            {checkedData[index]?.checked ? (
                                              <>
                                                <label
                                                  style={{
                                                    color: "#d9ccc5",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  Quantity
                                                </label>
                                                <select
                                                  style={{
                                                    height: "30px",
                                                    width: "150px",
                                                    padding: "0 10px",
                                                  }}
                                                  placeholder="Available venue"
                                                  className="enquiry-input"
                                                  onChange={(e) => {
                                                    if (
                                                      checkedData[index]
                                                        ?.checked
                                                    ) {
                                                      setQuantityData({
                                                        ...quantityData,
                                                        [index]: {
                                                          quantity:
                                                            e.target.value,
                                                        },
                                                      });
                                                    } else {
                                                    }
                                                  }}
                                                >
                                                  <option>
                                                    Select Quantity
                                                  </option>

                                                  {options.map((item) => (
                                                    <>
                                                      <option value={item}>
                                                        {item}
                                                      </option>
                                                    </>
                                                  ))}
                                                </select>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </Stack>
                                        </Box>
                                      </Box>
                                    </>
                                  );
                                })}

                                {isStatus ? (
                                  <>
                                    <button className="btn-send">
                                      <Spinner
                                        variant="light"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn-send"
                                      onClick={() => createBill()}
                                    >
                                      Purchase
                                    </button>
                                  </>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
}
