import { Container, Grid } from "@mui/material";
import {React,useEffect} from "react";
import IMAGES from "../../../assets/images";
import BannerSlider from "../../../components/BannerSlider";
import ExclusiveBook from "../../../components/exclusive-book";
import SubHeader from "../../../components/layout/SubHeader";
import OfferSlider from "../../../components/OfferSlider";
import TestimonialSlider from "../../../components/TestimonalSlider";
import Slider from "react-slick";
import { useState } from "react";
import { showfeaturedstory } from "../../../api/frontend";
import { Featured_URL } from "../../../serverUrl";

const { villasamadhi, taste1, villasamadhi7473, stroy11, ggOne } = IMAGES;
const VSKLThaipusamExperienceOne = () => {
  const [experienceArray, SetexperienceArray] = useState([]);
  const handlefeaturedstory = async () => {
    const response = await showfeaturedstory();

    if (response.remote === "success") {
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlefeaturedstory();
    window.scrollTo(0, 0);
  }, []);
  const BannerArray = [villasamadhi, villasamadhi];
  const tasteCardArray = [
    {
      image: ggOne,
      type: "Story",
      title: "Colonial heritage",
      cardClass: "mt-5",
      description:
        " Villa Samadhi is housed in an old 1920s colonial garrison. In fact the bungalow, now the premises of Tamarind Hill, was originally built in 1881 and was later gifted to the government. The building, now occupied by Villa Samadhi, was a British Army artillery garrison, after its completion in the 1920s.",
      id: "1",
      Animation: "fade-up",
    },
    {
      image: villasamadhi7473,
      type: "STORY",
      title: "Tamarind Hill, Singapore",
      Animation: "fade-down",
      description:
        " Villa Samadhi guests will be pleased to discover that they share the premises with a Tamarind Hill restaurant. One of Samadhi’s most celebrated properties, its presence ensures that there’ll be no shortage of delicious, authentic Thai and Shan Cuisine close at hand.",
      id: "2",
    },
  ];

  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="experience-section-page experience-thaipusam-page-one">
        <div className="experience-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Villa Samadhi, Singapore"
                subHeading={[
                  { id: "0", subLink: "Gallery", subUrl: "#" },
                  { id: "1", subLink: "Stays", subUrl: "/stay" },
                  { id: "2", subLink: "Tastes", subUrl: "/taste" },
                  { id: "3", subLink: "Story", subUrl: "/experience" },
                  { id: "4", subLink: "Senses", subUrl: "#" },
                  {
                    id: "5",
                    subLink: "Celebrations",
                    subUrl: "/celebration-and-event",
                  },
                  { id: "6", subLink: "Gift card", subUrl: "#" },
                  {
                    id: "7",
                    subLink: "Offers",
                    subUrl: "/japamala-resort-tioman-island/offers",
                  },
                ]}
              />
            </div>
          </Container>
          <div className="vskl-experience">
            <Container maxWidth="xl" className="border-dashed-ex">
              <h3>Life at the park</h3>
            </Container>
            <Container maxWidth="xl" className="banner_container">
              <div
                className="banner-div"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <BannerSlider BannerArray={BannerArray} />
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div className=" experience-content-text text-center ">
              <Grid container justifyContent="center">
                <Grid items xl={7} lg={7}>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration="3000"
                  >
                    Situated within the grounds of the Labrador Nature Reserve,
                    surrounded by jungle and wildlife, tranquillity prevails.
                    That is save for the bird calls of magpies and orioles. Or
                    the rustling of leaves as squirrels scamper by.
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration="3000"
                  >
                    {" "}
                    But there’s more to this forest reserve. A number of nature
                    trails - some relaxed, others more challenging - are perfect
                    for birdwatchers or hiking enthusiasts. There’s also a
                    promenade trail, complete with exercise equipment stations,
                    that’s frequented by joggers and a few fishermen. History
                    buffs will appreciate the heritage trail which includes a
                    network of old WWII pillboxes and defensive fortifications.
                    Speaking of heritage, at Villa Samadhi you actually live it.
                    The site of an old 1920s Colonial garrison, the bungalow has
                    been restored to its former glory! Waking up to mornings
                    with the sounds of nature, taking breakfast in your room and
                    musing about a bygone era, it’s the next best thing to
                    travelling back in time.{" "}
                  </p>{" "}
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration="3000"
                  >
                    Yoga at the Soma wellness retreat is also an experience not
                    to be missed. There’s something about performing a pose
                    while being surrounded by nature that is extremely
                    invigorating! If you prefer less stretching and are looking
                    for a more passive form of relaxation then check out the
                    different massages available.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Container>
          <div className="vsklexprice-banner">
            <Container maxWidth="xl">
              <div className="center-slider exprcne py-100 ">
                <div className="normal-card-section">
                  <Grid container spacing={3}>
                    {tasteCardArray.map((content) => {
                      return (
                        <Grid
                          data-aos={content.Animation}
                          data-aos-duration="3000"
                          data-aos-once="true"
                          xs={12}
                          item
                          md={6}
                          className={`normal-card-div px-3 ${content.cardClass}`}
                          key={content.id}
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Featured_URL}${item.image}`} alt=""
                        
                        loading="lazy"/>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
          <Container maxWidth="xl">
            <ExclusiveBook
              bgcolor="#F8F4F4"
              color="#FFFFFF"
              textcolor="#050505"
              bordercolor="#707070"
              heddingcolor="#403836"
            />
          </Container>
        </div>
      </div>
    </>
  );
};
export default VSKLThaipusamExperienceOne;
