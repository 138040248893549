import { Container, Divider, Grid } from "@mui/material";
import { React, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IMAGES from "../../assets/images";
import BannerSlider from "../../components/BannerSlider";
import ExclusiveBook from "../../components/exclusive-book";
import SubHeader from "../../components/layout/SubHeader";
import OfferSlider from "../../components/OfferSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  RetreatRoomDetails,
  RetreatRoomList,
  geetingDetailsData,
  retreatsGalleryDetailsData,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import { Button, Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
const { vsklroombanner2, crib1, crib3, crib2, crib4, food, vsklImg, vsklTwo } =
  IMAGES;
const VsklRoomType2 = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [retreatIds, setRetreatsid] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatstitle, setRetreatstitle] = useState("");
  const [retreatslocation, setRetreatslocation] = useState("");
  const [booking, setBooking] = useState("");
  const [roomIds, setRoomId] = useState("");
  const [masterTitle, setMasterTitle] = useState("");
  const [guestTitle, setGuestTitle] = useState("");
  const [roomDetails, setRoomDetails] = useState([]);
  const [roomGallery, setRoomGallery] = useState([]);
  const [roomFeatures, setRoomFeatures] = useState([]);
  const [guestroomFeatures, setGuestRoomFeatures] = useState([]);
  const [roomArray, setRoomArray] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);
  const [retreatsMetaTitleData, setRetreatsMetaTitleData] = useState('');
const [retreatsMetaDiscriptionData, setRetreatsMetaDiscriptionData] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const BannerArray = [vsklroombanner2, vsklroombanner2];
  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setExclusiveOffers(response.data.exclusive);
      setRetreatsid(response.data.data[0].id);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatstitle(response.data.data[0].title);
      setRetreatslocation(response.data.data[0].other);
      setBooking(response.data.data[0].booking);
    } else {
    }
  };
  const handleroomDetails = async (roomId) => {
    setIslodingData(true);

    const response = await RetreatRoomDetails(roomId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRoomDetails(response.data.data);
      setRoomGallery(JSON.parse(response.data.data[0].gallery));
     setMasterTitle(response.data.data[0].mastertitle);
      setGuestTitle(response.data.data[0].guesttitle);
      setRetreatsMetaTitleData(response.data.data[0].meta_title);
      setRetreatsMetaDiscriptionData(response.data.data[0].meta_description);
      // setRoomFeatures(JSON.parse(response.data.data[0].room_features));

      let tempArr = JSON.parse(response.data.data[0].room_features);
      setRoomFeatures(tempArr.sort((a, b) => a.order - b.order));
      let tempArray = JSON.parse(response.data.data[0].guest_features);
      setGuestRoomFeatures(tempArray.sort((a, b) => a.guestOrder - b.guestOrder));
    } else {
    }
  };
  const handleroomList = async (catagoryId) => {
    setIslodingData(true);

    const response = await RetreatRoomList(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      let tempArr = response.data.data;
      setRoomArray(tempArr.sort((a, b) => Number(a.sequence) - Number(b.sequence)));
      // setRoomArray(response.data.data);
    } else {
    }
  };

  const exclusiveOffer = [];
  exclusiveOffers?.map((item) => {
    exclusiveOffer.push({
      image: item.image,
      subcategory: "RETREAT",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      slug: item.other,
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  const responsiveCelebration = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const responsiveNormal = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const handleOpenLink = (link) => {
    let result = link.split("/")[0];
    if (result === "https:" || result === "http:") {
      window.open(link, "_blank");
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };
  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0])


    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");

    const roomId = searchParams.get("roomId");
    setRoomId(roomId);
    handleretreatsDetails(catagoryId);
    handleroomDetails(roomId);
    handleroomList(catagoryId);
    handlegettingDetails(catagoryId);
    window.scrollTo(0, 0);
    if(catagoryId === '16'){
      const script = document.createElement("script");
    script.src = "https://static.sojern.com/sdk/latest/sojern.min.js";
    script.type = "text/javascript";
    script.async = true;

    const inlineScript = document.createElement("script");
    inlineScript.type = "text/javascript";
    inlineScript.innerHTML = `
    window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
    sjn('initAndFire', 
        'advertiser', 
        '15207', 
        'tracking', { 
        context: { vertical: 'hotel' },
        params: {}
    })
    `;

    // Append both scripts to the body
    document.body.appendChild(script);
    document.body.appendChild(inlineScript);

    return () => {
      // Clean up script elements if the component unmounts
      document.body.removeChild(script);
      document.body.removeChild(inlineScript);
    };
    }else if(catagoryId === '17'){
      // Load the external script for sjrn_autocx
      const script = document.createElement("script");
      script.src = "https://static.sojern.com/utils/sjrn_autocx.js";
      script.type = "text/javascript";
      script.async = true;

      const inlineScript = document.createElement("script");
      inlineScript.type = "text/javascript";
      inlineScript.innerHTML = `
        (function () {
          var params = {
            hpr: "JapaMala Resorts", /* Hotel Property */
            hpid: "65c451e2-21c7-454a-8fab-d3dd692d78e8", /* Property ID */
            sha256_eml: "", /* Hashed Email SHA256 */
            sha1_eml: "", /* Hashed Email SHA1 */
            md5_eml: "", /* Hashed Email MD5 */
            ccid: "", /* Client Cookie id */
            pt: "HOME_PAGE" /* Page Type - CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */
          };

          params.et = {"HOME_PAGE": null, "SEARCH": "hs", "PRODUCT": "hpr", "SHOPPING_CART": "hcart", "CONVERSION": "hc", "TRACKING": null}[params.pt] || '';
          
          try { params = Object.assign({}, sjrn_params || {}, params); } catch (e) {}

          var paramsArr = [];
          for (var key in params) {
            paramsArr.push(key + '=' + encodeURIComponent(params[key]));
          }

          var iframe = document.createElement('iframe');
          iframe.type = 'text/html';
          iframe.setAttribute('style', 'height:0; width:0; display:none;');
          iframe.async = true;
          iframe.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');

          document.body.appendChild(iframe);
        })();
      `;

      // Append the external and inline scripts to the body
      document.body.appendChild(script);
      document.body.appendChild(inlineScript);

      return () => {
        // Clean up script elements if the component unmounts
        document.body.removeChild(script);
        document.body.removeChild(inlineScript);
      };
    }
  }, [searchParams]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{retreatsMetaTitleData}</title>
        <meta name="description" content={retreatsMetaDiscriptionData} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="room-type-page room-type-two">
        <div className="main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div mb-5">
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={6}
                    className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                  >
                    <h3 className="cursor-btn">
                      {retreatstitle && retreatstitle !== undefined
                        ? `${retreatstitle}, ${retreatslocation}`
                        : ""}
                      <span className="ms-2">{subMenuArrow}</span>
                    </h3>
                  </Col>
                  <Col lg={6}>
                    <ul className="ps-0 pt-20 ">
                      <li>
                       
                        <Button
                          className="active"
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/rooms?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Rooms
                        </Button>
                      </li>
                      <li>
                       
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/dining?retreatId=${retreatIds}`
                            )
                          }
                        >
                          {" "}
                          Dining
                        </Button>
                      </li>
                     
                      <li>
                       

						 {retreatIds !== 18 ? <>
                    <Button
                      onClick={() =>
                        navigate(
                          `/vskl-celebration?retreatId=${retreatIds}`
                        )
                      }
                    >
                      {" "}
                      Wellness
                    </Button>
				  </>:""}

                      </li>
                      <li>
                        
                        <Button
                          onClick={() =>
                            navigate(
                              `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                            )
                          }
                        >
                          Offers
                        </Button>
                      </li>
                      <li>
                       
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/gallery?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Gallery
                        </Button>
                      </li>
                      <li>
                        <Button onClick={() => navigate(`/travels-tours`)}>
                          Activities
                        </Button>
                      </li>
                     
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div pb-0"
              data-aos="fade-up"
              data-aos-duration="3000"
              // data-aos-once="true"
            >
              <BannerSlider BannerArray={roomGallery} />
            </div>
          </Container>

          <div className="crib-roomtype">
            <Container maxWidth="xl">
              <div className="card-description pb-100 border-btm mb-0">
                <h4
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="3000"
                >
                  {roomDetails[0]?.title}
                </h4>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    data-aos-once="true"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="w-75">
                   
                      <p>{roomDetails[0]?.longdescription}</p>
                    </div>{" "}
                    <div className="btn-reserbox boxed">
                      <button
                        className="dashed-btn"
                        onClick={() => handleOpenLink(booking)}
                      >
                        Reservation
                      </button>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    data-aos-once="true"
                    data-aos="fade-down"
                    data-aos-duration="3000"
                  >
                    <div className="room-features">
                      <h5>Room Features</h5>
                      <h6>{masterTitle === "null" ? "" : masterTitle}</h6>

                      <ul>
                        {roomFeatures?.map((item) => {
                          return (
                            item.roomFeature && <li>{item.roomFeature}</li>
                          );
                        })}
                      </ul>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    data-aos-once="true"
                    data-aos="fade-down"
                    data-aos-duration="3000"
                  >
                    <div className="room-features">
                      <h6>&nbsp;</h6>

                      <h6>{guestTitle === "null" ? "" : guestTitle}</h6>

                      <ul>
                        {guestroomFeatures?.map((item) => {
                          return (
                            item.guestRoomFeature && (
                              <li>{item.guestRoomFeature}</li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <div
            className=""
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <Container maxWidth="xl">
              {" "}
              <div className="exprcne ex-homepage btn-slider-div border-btm">
                <Carousel
                  speed={2000}
                  arrows={true}
                  // ssr={true}
                  partialVisbile={roomArray.length < 3 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsiveCelebration}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {roomArray.slice(0, 5).map((item, index) => {

                    return (
                      Number(roomIds) !== Number(item.id) && (
                        <>
                          <div
                            className={`exprice-slider  c-slide_image`}
                            key={index}
                            onClick={() => {
                               const formattedTitle = item.title
                                    .replace(/\s+/g, "-")
                                    .toLowerCase();
                              navigate(
                                `/${retreatsslug}/rooms/${formattedTitle}?retreatId=${retreatIds}&roomId=${item.id}`
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            <img src={`${Offers_URL}${item.cardImage}`}
                            loading="lazy"/>
                          
                            <h3>{item.title}</h3>
                            <p>{item.shortDescription}</p>
                          </div>{" "}
                          <div className="btn-reserbox boxed">
                            <button
                              className="dashed-btn"
                              onClick={() => handleOpenLink(booking)}
                            >
                              Reservation
                            </button>
                          </div>
                        </>
                      )
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
             
            </Container>
            <Container maxWidth="xl">
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={false}
                  sliderClass="centerslider"
                  responsive={responsiveNormal}
                  autoPlay={false}
                  infinite={false}
                >
                  {exclusiveOffer.map((item, index) => (
                    <div
                      key={index}
                      data-aos-once="true"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      className={`bottom-cards-div  cursor-btn ${item.cardClass}`}
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>
       
          <Container maxWidth="xl">
            <ExclusiveBook
              bgcolor="#403836"
              color="#fff"
              textcolor="#050505"
              bordercolor="#707070"
              heddingcolor="#D9CCC5"
            />
          </Container>
        </div>
      </div>
    </>
  );
};
export default VsklRoomType2;
