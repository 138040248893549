import urlcat from "urlcat"
import axiosInstance from "./api";

export const addNewsLetter = async (newsLetterData) => {
  const data = new FormData();
  data.append("name", newsLetterData.name);
  data.append("email", newsLetterData.email);

  const response = await axiosInstance.request({
    url: "addnewslatter",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showjobDiscription = async () => {


  const response = await axiosInstance.request({
    url: "show_job_page_discription",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showjob = async () => {


  const response = await axiosInstance.request({
    url: "show_job",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showfeaturedstory = async () => {


  const response = await axiosInstance.request({
    url: "show_featured",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const celebrationHomeText = async () => {


  const response = await axiosInstance.request({
    url: "celebrationText",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showExperienceBannner = async () => {


  const response = await axiosInstance.request({
    url: "showExperienceBanner",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showStoryTitle = async () => {


  const response = await axiosInstance.request({
    url: "showexperienceCategory",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showExperienceList = async () => {
  const response = await axiosInstance.request({
    url: "showExperience",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const showExperienceIdsList = async (id) => {
  const response = await axiosInstance.request({
    url: `showExperienceIdsList/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const findExperienceList = async (id) => {


  const response = await axiosInstance.request({
    url: `findExperienceDeatils/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const privacyDetail = async () => {


  const response = await axiosInstance.request({
    url: "privacyDetails",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const accoladesDetails = async () => {


  const response = await axiosInstance.request({
    url: "accoladesDetails",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const digitalgiftData = async () => {


  const response = await axiosInstance.request({
    url: "digitalgift",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const listData = async () => {


  const response = await axiosInstance.request({
    url: "Restaurant_Retreats_Lists",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const addbookinggift = async (addgiftbookinPayload) => {
  const data = new FormData();

  data.append("giftId", addgiftbookinPayload.giftId);
  data.append("type", addgiftbookinPayload.type);
  data.append("location", addgiftbookinPayload.location);
  data.append("value", addgiftbookinPayload.value);
  data.append("quantity", addgiftbookinPayload.quantity);
  data.append("f_name", addgiftbookinPayload.f_name);
  data.append("l_name", addgiftbookinPayload.l_name);
  data.append("reciver_email", addgiftbookinPayload.reciver_email);
  data.append("reciver_phone", addgiftbookinPayload.reciver_phone);
  data.append("message", addgiftbookinPayload.message);
  data.append("sender_name", addgiftbookinPayload.sender_name);
  data.append("sender_phone", addgiftbookinPayload.sender_phone);
  data.append("sender_email", addgiftbookinPayload.sender_email);


  const response = await axiosInstance.request({
    url: "giftCardUser",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const exclusiveoffers = async () => {


  const response = await axiosInstance.request({
    url: "showExclusiveOfferfront",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const exclusiveofferswithId = async (id) => {


  const response = await axiosInstance.request({
    url: `showExclusiveOfferfrontwithid/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const homecontent = async (id) => {


  const response = await axiosInstance.request({
    url: `gethomecontent`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const exclusiveoffersCategory = async () => {


  const response = await axiosInstance.request({
    url: "exclusiveCategoryLists",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const exclusiveoffersdetails = async (showexclusivePayload) => {


  const response = await axiosInstance.request({
    url: `showExclusiveOfferslug/${showexclusivePayload.slug}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const celebrationBanners = async () => {


  const response = await axiosInstance.request({
    url: 'showCelebrationBanner',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const celebrationMalasiyaBanners = async () => {


  const response = await axiosInstance.request({
    url: 'celebrationDetailsmalasiya',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const celebrationSingaporeBanners = async () => {


  const response = await axiosInstance.request({
    url: 'celebrationDetailssingapore',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const celebrationBlock = async () => {


  const response = await axiosInstance.request({
    url: 'celebrationBlockDetails',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const experienceCategory = async () => {


  const response = await axiosInstance.request({
    url: 'showexperienceCategory',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const celebrationdetails = async (celebrationPayload) => {


  const response = await axiosInstance.request({
    url: `showcelebrationBlock/${celebrationPayload.slug}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const restaurantNameList = async () => {


  const response = await axiosInstance.request({
    url: 'restaurantnamelist',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const restaurantDetails = async () => {


  const response = await axiosInstance.request({
    url: 'restaurantbannerList',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const restaurantItems = async () => {


  const response = await axiosInstance.request({
    url: 'restaurantItemsList',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
/******* Changes in before live *************/ 
export const restaurantItemsid = async (restaurantData) => {


  const response = await axiosInstance.request({
    url: `restaurantItemsId/${restaurantData.id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const footerSection = async () => {


  const response = await axiosInstance.request({
    url: 'show_footer',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const TravelBannerSection = async () => {


  const response = await axiosInstance.request({
    url: 'tarvelBanner',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const TravelCategorySection = async () => {


  const response = await axiosInstance.request({
    url: 'travelCategory',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const TravelSection = async () => {


  const response = await axiosInstance.request({
    url: 'travelPackage',
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const travelItemsid = async (travelId) => {
  
  const response = await axiosInstance.request({
    url: `travelItemsId/${travelId.id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const retreatsDetailsData = async (id) => {
  
  const response = await axiosInstance.request({
    url: `retreatsDetails/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const checkretreatsDetailsData = async (id) => {
  
  const response = await axiosInstance.request({
    url: `checkretreatsDetails/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const retreatsGalleryDetailsData = async (id) => {
  
  const response = await axiosInstance.request({
    url: `retreatsGallery/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
export const geetingDetailsData = async (id) => {
  
  const response = await axiosInstance.request({
    url: `findgetting/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

  export const RetreatCategorySection = async () => {
    
    
      const response = await axiosInstance.request({
        url: 'retreatsCategoryData',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const sidebarData = async (type) => {
    
    
      const response = await axiosInstance.request({
        url: `forntlistsidebar/${type}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const setTotalList = async () => {
    
    
      const response = await axiosInstance.request({
        url: 'showtotalList',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const RetreatBannerSection = async () => {
    
    
      const response = await axiosInstance.request({
        url: 'retreatsBannerData',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const RetreatSection = async () => {
    
    
      const response = await axiosInstance.request({
        url: 'retreatsData',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const AboutSection = async () => {
    
    
      const response = await axiosInstance.request({
        url: 'getaboutcontent',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const RetreatRoomDetails = async (id) => {
    
    
      const response = await axiosInstance.request({
        url: `retreatsRoomDetails/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
  export const RetreatRoomList = async (id) => {
    
    
      const response = await axiosInstance.request({
        url: `retreatsRoomList/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const ExclusiveBlock = async (id,type) => {


      const response = await axiosInstance.request({
        url: urlcat(`retreatsExclusiveOffers/:id/:type`, {id, type}),
        // url: `retreatsExclusiveOffers/${id}/${type}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const CruiseLanding = async () => {


      const response = await axiosInstance.request({
        url: 'findCruiseLanding',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const Cruisepackage = async () => {


      const response = await axiosInstance.request({
        url: 'CruiseLists',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const CruisepackageList = async () => {


      const response = await axiosInstance.request({
        url: 'CruisepackageEnquiry',
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const CruisepackageEnquiry = async (id) => {


      const response = await axiosInstance.request({
        url: `CruiseListsEnquiry/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    export const FindCruisepackage = async (id) => {


      const response = await axiosInstance.request({
        url: `FindCruiseLists/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };

    export const FindCruiseenquery = async (id) => {


      const response = await axiosInstance.request({
        url: `FindCruiseEnqiuryList/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };

    export const addEnquiryDetail = async (jobDetailData) => {
      const data = new FormData();
      data.append("firstName", jobDetailData.firstName);
      data.append("lastName", jobDetailData.lastName);
      data.append("email", jobDetailData.email);
      data.append("phone", jobDetailData.phone);
      data.append("date", jobDetailData.date);
      data.append("description", jobDetailData.description);
    
      const response = await axiosInstance.request({
        url: "cruiseenquiry",
        method: "POST",
        data,
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };

    export const manageticketDataId = async (id) => {
      const response = await axiosInstance.request({
        url: `manageTicketData/${id}`,
        method: "GET",
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };

    export const checkPayment = async (addgiftbookinPayload) => {

      const data = new FormData();
    
      data.append("name", addgiftbookinPayload.name);
      data.append("email", addgiftbookinPayload.email);
      data.append("mobile", addgiftbookinPayload.mobile);
      data.append("amount", addgiftbookinPayload.amount);
      data.append("checkedData", JSON.stringify(addgiftbookinPayload.checkedData));
      const response = await axiosInstance.request({
        url: `createPayment`,
        method: "POST",
        data,
        header: {
          "Content-Type": "application/json",
        },
      });
    
      return response;
    };
    