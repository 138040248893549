import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import BannerSlider from "../../components/BannerSlider";
import { Link, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ExclusiveCard from "../VSKL/ExclusiveCard";
import { useState } from "react";
import GiftConfirmModal from "../../components/giftModal/confirmModal";
import { Featured_URL } from "../../serverUrl";
import { addbookinggift } from "../../api/frontend";

const {
  digitalPic,

  vsklBanner,
  jungleMan,
  vsklTasteBanner,
  resturentNew,
  building,
  taste3,
  cooking,
  giftOne,
  giftTwo,
} = IMAGES;
const BannerArray = [vsklTasteBanner, vsklBanner];
var tasteSlider = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export default function DigitalGiftCardBooking() {

  const {state} = useLocation()

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [recipientPhone, setRecipientPhone] = useState('');
  const [message, setMessage] = useState('');
  const [sendername, setSenderName] = useState('');
  const [senderemail, setSenderEmail] = useState('');
  const [senderphone, setSenderPhone] = useState('');

  const handleShowGiftConfirmModal = () => {
    setShowConfirmModal(true);
  };
  const handleGiftConfirmModalHide = () => {
    setShowConfirmModal(false);
  };
 
  const handleGiftcardBooking = async () => {
    const addgiftbookinPayload = {
    
  giftId:state.id,
  type:state.categorydata,
  location:state.locationdata,
  value:state.valuedata,
  quantity:state.qunatitydata,
  f_name:firstname,
  l_name:lastname,
  reciver_email:recipientEmail,
  reciver_phone:recipientPhone,
  message:message,
  sender_name:sendername,
  sender_phone:senderphone,
  sender_email:senderemail,
    };
    const response = await addbookinggift(addgiftbookinPayload);

    if (response.remote === "success") {
      // swal("Featured Story Successfully Added");
      // navigate("/admin/manage-featured-story");
      // setDescription("");
      // setTitle("");
      // setImgBanner("");
    } else {
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="vskl-ex-offer digital-gift-card">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Gifts"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Digital Gift Card",
                    subUrl: "/digital-gift-card",
                  },
                  //   {
                  //     id: "1",
                  //     subLink: "Experiences",
                  //     subUrl: "/gift-card-experience",
                  //   },
                ]}
              />
            </div>
          </Container>

          <div className="">
            <Container maxWidth="xl">
              <div className="exclusive-newnew ">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Digital Gift Card</h3>
                </Grid>
                <Grid container justifyContent="center" spacing={4}>
                  <Grid item md={10}>
                    <Grid className="testimonial-type-card mt-100">
                      <Grid
                        container
                        spacing={6}
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        {" "}
                        <Grid item md={7}>
                          <div className="gift-image-section">
                            <Slider {...tasteSlider}>
                              {JSON.parse(state?.image)?.map((items) => (
                                <img
                                  src={`${Featured_URL}${items}`}
                                  alt=""
                                  className="pt-200"
                                  loading="lazy"
                                />
                              ))}
                            </Slider>
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <Grid className="cards heading exclusive-card-data">
                            <h4 className="mb-5">Recipient Details</h4>

                            <input
                              placeholder="Recipient first name"
                              className="enquiry-input w-100 mt-5 mb-3"
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                            <input
                              placeholder="Recipient last name"
                              className="enquiry-input w-100 mb-3"
                              onChange={(e) => setLastname(e.target.value)}
                            />
                            <input
                              placeholder="Recipient email"
                              className="enquiry-input w-100 mb-3 "
                              onChange={(e) =>
                                setRecipientEmail(e.target.value)
                              }
                            />
                            <input
                              placeholder="Recipient phone number"
                              className="enquiry-input w-100 mb-3 "
                              type="number"
                              onChange={(e) =>
                                setRecipientPhone(e.target.value)
                              }
                            />
                            <textarea
                              rows="8"
                              style={{ height: "200px" }}
                              placeholder="Message [optional]"
                              className="enquiry-input w-100 mb-3 "
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            <input
                              placeholder="Sender name"
                              className="enquiry-input w-100 mt-5 mb-3"
                              onChange={(e) => setSenderName(e.target.value)}
                            />
                            <input
                              placeholder="Sender   email"
                              className="enquiry-input w-100 mb-3"
                              onChange={(e) => setSenderEmail(e.target.value)}
                            />
                            <input
                              placeholder="Sender phone number"
                              className="enquiry-input w-100 mb-3 "
                              type="number"
                              onChange={(e) => setSenderPhone(e.target.value)}
                            />
                            <Button
                              className="dashed-btn mt-4"
                              onClick={handleShowGiftConfirmModal}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <GiftConfirmModal
        showConfirmModal={showConfirmModal}
        handleGiftConfirmModalHide={handleGiftConfirmModalHide}
        handleGiftcardBooking={handleGiftcardBooking}
      />
    </>
  );
}
