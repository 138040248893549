import { React, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReservationTab from "./ReservationTab";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  exclusiveoffers,
  exclusiveofferswithId,
  RetreatBannerSection,
  RetreatCategorySection,
  RetreatSection,
  showExperienceIdsList,
  showExperienceList,
} from "../../api/frontend";
import { useState } from "react";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
const {
  stayBanner,
  motorBikeOne,
  stayOne,
  stayTwo,
  stayThree,
  stayFour,
  dayTripOne,
  dayTripTwo,
  dayTripThree,
  experience1,
  experience3,
  experience2,
  experience4,
  yoga,
  hut,
  statue,
} = IMAGES;

export default function Stays() {
  const navigate = useNavigate();
  const [travelArray, setTravelArray] = useState([]);
  const [travelcategoryArray, setTravelCategory] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [retreatsDetails, setRetreatsDetails] = useState([]);
  const [MotorBikeArray, setMotorBikeArray] = useState([]);
  const [experienceListArray, SetExperienceListArray] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [searchParams] = useSearchParams();
  const [isLoadingData, setIslodingData] = useState(true);

  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleexclusive = async () => {
    setIslodingData(true);
    const id = "1";
    const response = await exclusiveofferswithId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setTravelArray(response.data.data);
    } else {
    }
  };
  const handleExperienceList = async () => {
    setIslodingData(true);

    const id = "1";
    const response = await showExperienceIdsList(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      SetExperienceListArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleexclusive();
    handleExperienceList();
  }, []);
  const handleretreats = async () => {
    setIslodingData(true);

    const response = await RetreatCategorySection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setTravelCategory(response.data.data);
    } else {
    }
  };
  const handleretreatsBanner = async () => {
    setIslodingData(true);

    const response = await RetreatBannerSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setBannerData(response.data.data);
    } else {
    }
  };
  const handleretreatsDetails = async () => {
    setIslodingData(true);

    const response = await RetreatSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRetreatsDetails(response.data.data);
    } else {
    }
  };
  const categoryList = [];
  travelcategoryArray?.map((item, index) => {
    categoryList.push({
      // id: item.id,
      subLink: item.title,
      // slug: item.slug,
      subUrl: `/${item.slug}?catagoryId=${item.id}`,
    });
  });

  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    setRestaurantId(catagoryId);
    const MotorBikeArray = [];
    const idMatchData = [];
    retreatsDetails?.map((item, index) => {
      if (Number(item.category) === Number(restaurantId)) {
        idMatchData.push({
          image: item.cardImage,
          subcategory: item.place,
          title: `${item.title}, ${item.other}`,
          shortDescription: item.shortDescription,
          id: item.id,
          slug: item.slug,
          speed: "3000",
          dataspeed: "fade-up",
        });
      } else {
        MotorBikeArray.push({
          image: item.cardImage,
          subcategory: item.place,
          title: `${item.title}, ${item.other}`,
          shortDescription: item.shortDescription,
          id: item.id,
          slug: item.slug,
          speed: "3000",
          dataspeed: "fade-up",
        });
      }
      if (restaurantId) {
        setMotorBikeArray([...idMatchData]);
      } else {
        setMotorBikeArray([...MotorBikeArray]);
      }
    });
  }, [searchParams, retreatsDetails]);
  useEffect(() => {
    handleretreats();
    handleretreatsBanner();
    handleretreatsDetails();
  }, []);
  useEffect(() => {
    
      const script = document.createElement("script");
    script.src = "https://static.sojern.com/sdk/latest/sojern.min.js";
    script.type = "text/javascript";
    script.async = true;

    const inlineScript = document.createElement("script");
    inlineScript.type = "text/javascript";
    inlineScript.innerHTML = `
    window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
    sjn('initAndFire', 
        'advertiser', 
        '15207', 
        'tracking', { 
        context: { vertical: 'hotel' },
        params: {}
    })
    `;

    // Append both scripts to the body
    document.body.appendChild(script);
    document.body.appendChild(inlineScript);

    return () => {
      // Clean up script elements if the component unmounts
      document.body.removeChild(script);
      document.body.removeChild(inlineScript);
    };
    
    }, []);
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{bannerData.meta_title}</title>
        <meta name="description" content={bannerData.meta_description} />
        <link rel="canonical" href="https://www.samadhiretreats.com/boutique-hotels-villas-resorts"/>
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="stay-page stay-home">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3>Retreats</h3>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
 
              data-aos-duration="3000"
            >
            
              <img src={`${Offers_URL}${bannerData.image}`} alt="" />
            </div>
          </Container>
          <div className="page-description-div">
            <Container maxWidth="xl">
              <Grid container></Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={7}
                  className="page-description-data"
                  data-aos="fade-up"
                  
                  data-aos-duration="3000"
                >
                  <h3 className="text-center mb-4 stay-title ">
                    {bannerData.title}
                  </h3>
                  <p>{bannerData.description}</p>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="motor-bike-section">
            <Container maxWidth="xl">
              <div className="normal-card-section cursor-btn">
                <Grid container spacing={4}>
                  {MotorBikeArray.map((content, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          data-aos="fade-up"
                          
                          data-aos-duration="2000"
                          onClick={() =>
                            navigate(
                              `/${content.slug}?catagoryId=${content.id}`
                            )
                          }
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        data-aos="fade-up"
                        // data-aos-once="true"
                        data-aos-duration="1500"
                        onClick={() =>
                          navigate(`/${content.slug}?catagoryId=${content.id}`)
                        }
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section   ">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Offers</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={travelArray.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {travelArray?.map((item, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div "
                          data-aos="fade-up"
                          // data-aos-once="true"
                          data-aos-duration="1500"
                          onClick={() => navigate(`/offers/${item.slug}`)}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div "
                        data-aos="fade-down"
                        // data-aos-once="true"
                        data-aos-duration="1500"
                        onClick={() => navigate(`/offers/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      // data-aos-once="true"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt=""
                        loading="lazy"/>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
          <ReservationTab
            data-aos="fade-up"
            // data-aos-once="true"
            data-aos-duration="3000"
          />
        </div>
      </div>
    </>
  );
}
