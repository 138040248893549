import { React, useEffect, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import BannerSlider from "../../components/BannerSlider";

import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import {
  showfeaturedstory,
  celebrationBanners,
  celebrationBlock,
  retreatsDetailsData,
  ExclusiveBlock,
  retreatsGalleryDetailsData,
  geetingDetailsData,
} from "../../api/frontend";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
const { celebrationBanner } = IMAGES;

export default function VSKLExclusiveOfferCategory() {
  const navigate = useNavigate();
  const [experienceArray, SetexperienceArray] = useState([]);
  const [BannerArray, setBannerArray] = useState([]);
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [retreatsDetails, setRetreatsDetails] = useState([]);
  const [retreatsRoomArray, setRetreatsRoomArray] = useState([]);
  const [searchParams] = useSearchParams();
  const [restaurantId, setRestaurantId] = useState("");
  const [retreatsTitle, setRetreatsTitle] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsLocation, setRetreatsLocation] = useState("");
  const [isLoadingData, setIslodingData] = useState(true);


  const handleCelebrationBanner = async () => {
    setIslodingData(true);

    const response = await celebrationBanners();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setBannerArray(response.data.data);
    } else {
    }
  };
  const handleExclusiveBlock = async (id) => {
    setIslodingData(true);

    const response = await ExclusiveBlock(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleCelebrationBanner();
  }, []);

  let rows = [];

  BlockCelebration?.map((item, index) => {
    rows.push({
      image: item.image,
      subcategory: "Exculsive Offers",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      slug: item.other,
      speed: "fade-up",
      dataspeed: "1500",
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRetreatsTitle(response.data.data[0].title);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsLocation(response.data.data[0].other);
    } else {
    }
  };

  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0])


    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    setRestaurantId(catagoryId);
    handleExclusiveBlock(catagoryId);
    handleretreatsDetails(catagoryId);
    handlegettingDetails(catagoryId);
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Boutique Hotels, Villas, Resorts & Residences | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="Each Samadhi Retreats properties offer a unique sense of place, rooted in nature, culture and heritage. Discover more."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="celebration-and-event-page celebration-home vskl-ex-offer">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={6}
                className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
              >
                <h3
                  className="cursor-btn"
                  onClick={() =>
                    navigate(`/${retreatsslug}?catagoryId=${restaurantId}`)
                  }
                >
                  {retreatsTitle && retreatsTitle !== undefined
                    ? `${retreatsTitle}, ${retreatsLocation}`
                    : ""}
                  <span className="ms-2">{subMenuArrow}</span>
                </h3>
              </Col>
              <Col lg={6}>
                <ul className="ps-0 pt-20 ">
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/rooms?retreatId=${restaurantId}`
                        )
                      }
                    >
                      Rooms
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/dining?retreatId=${restaurantId}`
                        )
                      }
                    >
                      {" "}
                      Dining
                    </Button>
                  </li>

                  <li>
                    <Button
                      onClick={() =>
                        navigate(`/vskl-celebration?retreatId=${restaurantId}`)
                      }
                    >
                      {" "}
                      Wellness
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="active"
                      onClick={() =>
                        navigate(
                          `/exclusive-offer-details?retreatId=${restaurantId}&type=retreats`
                        )
                      }
                    >
                      Offers
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/gallery?retreatId=${restaurantId}`
                        )
                      }
                    >
                      Gallery
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div
            className="banner-div"
            data-aos="fade-up"
            data-aos-duration="3000"
            // data-aos-once="true"
          >
            <img src={celebrationBanner} alt="" loading="lazy" />
          </div>

          <div className="celebration_cards_main">
            <Container maxWidth="xl">
              <div className="normal-card-section">
                <Grid container spacing={4}>
                  {rows?.map((content, index) => {
                    return (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        data-aos={content.speed}
                        data-aos-once="true"
                        data-aos-duration={content.dataspeed}
                        onClick={() => navigate(`/offers/${content.slug}`)}
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
